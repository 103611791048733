<template>
    <div class="con-wrap">
        <CarrotTitle title="사보 당근농장 관리"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <table v-if="bbs.isLoaded==true" class="table-row">
                    <colgroup>
                        <col width="180">
                        <col width="*">
                        <col width="180">
                        <col width="200">
                        <col width="180">
                        <col width="200">
                    </colgroup>                    
                    <tbody>
                        <tr>
                            <th>발행년도</th>
                            <td colspan="5">
                                {{ bbs.info.year }}년
                            </td>
                        </tr>
                        <tr>
                            <th>발행월</th>
                            <td colspan="5">
                                {{ bbs.info.month }}월
                            </td>
                        </tr>
                        <tr>
                            <th>제목</th>
                            <td colspan="5">
                                {{ bbs.info.title }}
                            </td>
                        </tr>
                        <tr>
                          <th>미리보기</th>
                          <td colspan="5">
                            {{ bbs.info.preview }}
                          </td>
                        </tr>
                        <tr v-if="bbs.info.file1_name">
                            <th>썸네일</th>
                            <td colspan="5">
                                <a :href="bbs.info.file1_url" :download="bbs.info.file1_name" class="btn-view" target="_blank">{{ bbs.info.file1_name }}</a>
                            </td>
                        </tr>
                        <tr v-for="(irow, i) in bbs.info.idx_list" :key="i">
                            <th>인덱스 제목{{i+1}}</th>
                            <td colspan="5">
                                <div>{{irow.index_title}}</div>
                                <div>
                                    <a :href="irow.index1_url" :download="irow.index1_name" target="_blank">{{ irow.index1_name }}</a>
                                </div>
                                <div>
                                    <a :href="irow.index2_url" :download="irow.index2_name" target="_blank">{{ irow.index2_name }}</a>
                                </div>
                                <div>
                                    <a :href="irow.index3_url" :download="irow.index3_name" target="_blank">{{ irow.index3_name }}</a>
                                </div>
                                <div>
                                    <a :href="irow.index4_url" :download="irow.index4_name" target="_blank">{{ irow.index4_name }}</a>
                                </div>
                                <div>
                                    <a :href="irow.index5_url" :download="irow.index5_name" target="_blank">{{ irow.index5_name }}</a>
                                </div>
                                <div>
                                    <a :href="irow.index6_url" :download="irow.index6_name" target="_blank">{{ irow.index6_name }}</a>
                                </div>
                                <div>
                                    <a :href="irow.index7_url" :download="irow.index7_name" target="_blank">{{ irow.index7_name }}</a>
                                </div>
                                <div>
                                    <a :href="irow.index8_url" :download="irow.index8_name" target="_blank">{{ irow.index8_name }}</a>
                                </div>
                                
                            </td>
                        </tr>
                        
                    </tbody>
                </table>

                <button class="btn-default float-left mt-30" @click="bbs.showList()">목록</button>
                <button class="btn-default float-left mt-30 ml-10" @click="bbs.doDelete()">삭제</button>
                <button class="btn-default float-right mt-30" @click="bbs.showMod()">수정</button>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'

export default {
    layout:"ResourceManagement",
    components: {
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const toast = useToast()

        const bbs = reactive({
            isLoaded : false,

            idx : 0,
            info : {},

            showList: () => {
                router.push({ name:"ResourceManagement-NewsLetterManagementList" });
            },

            doDelete : () => {
                Swal.fire({
                    title : "사보 당근농장 관리",
                    text: '삭제하시겠습니까?',
                    showCancelButton: true,
                }).then((res) => {
                    if (res.isConfirmed) {
                        let params = {
                            idx : bbs.idx
                        };
                        axios.post("/rest/resourceManagement/newsLetterDel", params).then((res) => {
                            if( res.data.err == 0 ){
                                router.push({
                                    name: 'ResourceManagement-NewsLetterManagementList'
                                })
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            showMod: () => {
                router.push({
                    name   : 'ResourceManagement-NewsLetterManagementMod-idx',
                    params : { idx:bbs.idx }
                });
            },

            getInfo: () => {
                let params = {
                    idx : bbs.idx
                };
                axios.get("/rest/resourceManagement/newsLetterView", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.info = res.data;
                        bbs.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });
        onMounted(() => {
            // Mounted
            bbs.idx = route.params.idx;
            if ( !bbs.idx ) {
                router.go(-1);
            } 
            bbs.getInfo();
        });

        watch(() => route.params.idx, (nVal, oVal) => {
            if( nVal != oVal && nVal > 0 ){
                bbs.idx = nVal;
                bbs.getInfo();
            }
        })

        return { bbs };
    }
}
</script>

<style lang="scss" scoped>
.board {
    .contents {
        img {
            max-width : 100%;
        }
    }
}
</style>